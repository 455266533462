const ENV = "dev" //will be replaced by dockerfile on build

export const isDev = (ENV === 'dev' || ENV === '@@##@@') //for local
const domain = isDev ? "wizecaredev.com" : "wizecare.com";
const projectId = isDev ? "wize-development-342714" : "wizecare-2021"

async function checkUrlExists(checkingUrl) {
    return await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('HEAD', checkingUrl, true);
        xhr.onload = function () {
            if (xhr.status === 200) {
                resolve(true);
            } else {
                resolve(false);
            }
        };
        xhr.onerror = function () {
            resolve(false);
        }
        xhr.send();
    });
}

export async function getVideoUrl(exerciseId) {
    let primary = '';
    let fallback = '';
    if (isDev) {
        primary = `https://storage.googleapis.com/wize-storage-dev/vtexercisevideos/white/en/${exerciseId}.mp4`;
        fallback = `https://media.wizecare.com/vtexercisevideos/white/en/${exerciseId}.mp4`;
    } else {
        primary = `https://media.wizecare.com/vtexercisevideos/white/en/${exerciseId}.mp4`;
        fallback = `https://storage.googleapis.com/wize-storage-dev/vtexercisevideos/white/en/${exerciseId}.mp4`;
    }

    if (await checkUrlExists(primary)) {
        return primary;
    } else {
        return fallback;
    }
}

export default {
    OT_API_KEY: isDev ? '45685722' : '45685722',//TODO: prod
    GET_TRACE_DATA_FUNC_URL: `https://europe-west1-${projectId}.cloudfunctions.net/get-maas-trace-data`,
    PROJECT_ID: projectId,
    serverUrl: `https://api.${domain}/vt/api/dispatcher.php`,
}
