import React, {useEffect, useState} from 'react';
import {Sidebar, Menu, MenuItem, useProSidebar, SubMenu} from 'react-pro-sidebar';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import MenuIcon from '@mui/icons-material/Menu';
import apiCalls from "../tools/apiCalls";
import {
    Autocomplete, Box,
    Button,
    FormControl,
    InputLabel,
    LinearProgress,
    Select,
    Switch,
    TextField, Typography
} from "@mui/material";
import ExerciseVideos from "./ExerciseVideos";
import ButtonGroup from '@mui/material/ButtonGroup';

function MainSideBar({onSubmit, moveAiExercises, moveAiLastSessions}) {
    const {collapseSidebar} = useProSidebar();
    const [isLocal, setIsLocal] = useState(false);
    //TODO is local and arch are the same?
    const [arch, setArch] = useState("MAAS");

    //TODO media name is hardcoded make it emum (apply to all listeners)
    const [media, setMedia] = useState("wizecare video gallery");
    const [frameRate, setFrameRate] = useState(30);
    const [speed, setSpeed] = useState(1);
    const [videoUrl, setVideoUrl] = useState("");
    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const [videoResolution, setVideoResolution] = useState({
        Height: 480,
        Width: 640,
    });
    const [moveAiLastSessionsValue, setMoveAiLastSessionsValue] = React.useState(null);
    const [mediaSelected, setMediaSelected] = useState(false);
    const [exerciseName, setExerciseName] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [isMirror, setIsMirror] = useState(false);
    const [logsLink, setLogsLink] = useState(null);

    useEffect(() => {},[logsLink])

    const handleSubmit = async (event) => {
        setSubmitting(true);
        event.preventDefault();
        if (arch === 'MOVE-AI') {
            try {
                const res = await apiCalls.getMoveAiSessionDataBySessionId(moveAiLastSessionsValue.sessionId);
                const formObj = {
                    arch,
                    res,
                };
                onSubmit(formObj);
                setSubmitting(false);
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                const exerciseConfig = await apiCalls.getConfig();
                const res = await apiCalls.launchMaas(selectedVideoId, arch !== 'LOCAL');

                setLogsLink(res?.logsLink ?? null);
                const formObj = {
                    arch,
                    media,
                    frameRate,
                    speed,
                    videoUrl,
                    videoResolution,
                    selectedVideoId: selectedVideoId,
                    OTdata: res?.OTdata ?? null,
                    exerciseConfig: exerciseConfig[selectedVideoId].config[0] ?? null,
                    moveAiSessionId: res?.moveAiSessions[0]?.sessionId,
                    isMirror
                };
                onSubmit(formObj);
                setSubmitting(false);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleMoveAiLastSessionsChange = (event, newValue) => {
        setArch('MOVE-AI')
        setMediaSelected(true);
        setMoveAiLastSessionsValue(newValue);
    };

    const handleArchitectureChange = () => {
        setIsLocal(!isLocal);
        setArch(isLocal ? "MAAS" : "LOCAL");
    };

    const handleMediaChange = (media) => {
        setMedia(media);
        setMediaSelected(true);
        setSelectedVideoId(null);
    };

    const handleFrameRateChange = (event) => {
        setFrameRate(event.target.value);
    };

    const handleSpeedChange = (event) => {
        setSpeed(event.target.value);
    };

    const handleVideoUrlChange = (url, id) => {
        setSelectedVideoId(id ?? null);
        setVideoUrl(url);
    };

    const handleVideoResolutionChange = (event) => {
        setVideoResolution(event.target.value);
    };

    const handleExerciseChange = (event) => {
        setSelectedVideoId(event.target.value.ex_id);
        setExerciseName(event.target.value.ex_name);
    };

    const isFormValid = () => {
        if (arch === 'MOVE-AI') {
            return moveAiLastSessionsValue !== null;
        } else {
            return mediaSelected && ((media !== "wizecare video gallery" && media !== "liveCamera") || selectedVideoId !== null);
        }
    };

    return (
        <div style={{display: 'flex', height: '100%', minHeight: '400px'}}>
            <Sidebar transitionDuration={1000} collapsedWidth="80px">
                <div style={{textAlign: 'center', marginTop: '10px'}}>
                    <h2>Move-AI Lab</h2>
                </div>
                <Menu
                    menuItemStyles={{
                        button: ({level, active, disabled}) => {
                            if (level === 0) {
                                return {
                                    color: disabled ? '#092327' : '#2196f3',
                                };
                            }
                        },
                    }}
                >
                    <MenuItem style={{marginBottom: '20px'}}>
                        <ButtonGroup variant="contained" color="primary">
                            <Button
                                onClick={() => handleMediaChange("liveCamera")}
                                color={media === "liveCamera" ? "secondary" : "primary"}
                                style={{fontSize: 20}}
                            >
                                📽️
                            </Button>
                            <Button
                                onClick={() => handleMediaChange("wizecare video gallery")}
                                color={media === "wizecare video gallery" ? "secondary" : "primary"}
                                style={{fontSize: 20}}
                            >
                                🎞️️
                            </Button>
                            <Button
                                onClick={() => handleMediaChange("jsonFile")}
                                color={media === "jsonFile" ? "secondary" : "primary"}
                                style={{fontSize: 20}}
                            >
                                📄
                            </Button>
                        </ButtonGroup>
                    </MenuItem>
                    {media !== "jsonFile" ? <MenuItem style={{marginBottom: '20px'}}>
                        <Box display="flex" alignItems="center">
                            <Typography style={{marginRight: '8px'}}>Local</Typography>
                            <Switch
                                checked={!isLocal}
                                onChange={handleArchitectureChange}
                                color="default"
                                inputProps={{'aria-label': 'architecture toggle'}}
                                style={{color: '#1975d0'}} // Set the toggle color here
                            />
                            <Typography style={{marginLeft: '8px'}}>MAAS</Typography>
                        </Box>
                    </MenuItem> : null}
                    {(
                        <>
                            {/* Video Gallery and file selection */}
                            <MenuItem style={{marginBottom: '20px'}}>
                                <ExerciseVideos
                                    onSelect={handleVideoUrlChange}
                                    moveAiExercises={moveAiExercises}
                                />
                            </MenuItem>
                            <MenuItem style={{marginBottom: '40px', marginTop: '40px'}}>
                                <InputLabel id="file-select-label">Select custom file</InputLabel>
                                <input
                                    type="file"
                                    accept=".mp4,.avi,.mov"
                                    onChange={(e) => setVideoUrl(URL.createObjectURL(e.target.files[0]))}
                                />
                            </MenuItem>
                        </>
                    )}
                    <MenuItem style={{ marginBottom: '5px',display:'none', }}>
                        <Box display="flex" alignItems="center">
                            <Typography style={{ marginRight: '8px' }}>Mirror</Typography>
                            <Switch
                                checked={isMirror}
                                onChange={() => setIsMirror(!isMirror)}
                                color="default"
                                inputProps={{ 'aria-label': 'flip toggle' }}
                                style={{
                                    display:'none',
                                    color: isMirror ? '#1975d0' : 'gray',
                                }}
                            />
                        </Box>
                    </MenuItem>

                    <SubMenu label="SETTINGS" disabled icon={<PhotoCameraBackIcon/>}>
                        <MenuItem style={{marginBottom: '20px'}}>
                            {/* Frame Rate */}
                            <InputLabel id="frameRate-label">Frame Rate</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    labelId="frameRate-label"
                                    id="frameRate"
                                    value={frameRate}
                                    onChange={handleFrameRateChange}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={45}>45</MenuItem>
                                </Select>
                            </FormControl>
                        </MenuItem>
                        <MenuItem style={{marginBottom: '20px'}}>
                            {/* Speed */}
                            <InputLabel id="speed-label">Speed</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    labelId="speed-label"
                                    id="speed"
                                    value={speed}
                                    onChange={handleSpeedChange}
                                >
                                    <MenuItem value={0.5}>0.5x</MenuItem>
                                    <MenuItem value={1}>1x</MenuItem>
                                    <MenuItem value={1.5}>1.5x</MenuItem>
                                    <MenuItem value={2}>2x</MenuItem>
                                </Select>
                            </FormControl>
                        </MenuItem>
                        <MenuItem style={{marginBottom: '20px'}}>
                            {/* Video Resolution */}
                            <InputLabel id="videoResolution-label">Video Resolution</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    labelId="videoResolution-label"
                                    id="videoResolution"
                                    value={videoResolution}
                                    onChange={handleVideoResolutionChange}
                                >
                                    <MenuItem value={{Height: 240, Width: 320}}>320x240</MenuItem>
                                    <MenuItem value={{Height: 480, Width: 640}}>640x480</MenuItem>
                                    <MenuItem value={{Height: 720, Width: 1280}}>1280x720</MenuItem>
                                    <MenuItem value={{Height: 1080, Width: 1920}}>1920x1080</MenuItem>
                                </Select>
                            </FormControl>
                        </MenuItem>
                    </SubMenu>
                    <MenuItem style={{marginBottom: '20px'}}>
                        {/* MoveAiLastSessions component */}
                        <FormControl fullWidth>
                            <Autocomplete
                                freeSolo
                                value={moveAiLastSessionsValue}
                                onChange={handleMoveAiLastSessionsChange}
                                options={moveAiLastSessions}
                                getOptionLabel={(option) => option.sessionId.toString()}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <TextField type="text" {...params.inputProps} label="Session ID"/>
                                    </div>
                                )}
                            />
                        </FormControl>
                    </MenuItem>
                    <div style={{flexGrow: 1}}/>
                    <MenuItem style={{marginBottom: '20px'}}>
                        {/* Submit Form button */}
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            color="primary"
                            // disabled={!isFormValid()}
                        >
                            Submit Form
                        </Button>
                    </MenuItem>
                    <MenuItem>
                        {submitting &&
                            <div style={{marginLeft: '40px', width: '50%'}}>
                                <LinearProgress/>
                            </div>
                        }
                    </MenuItem>

                    <MenuItem style={{marginBottom: '20px'}}>
                        {/* Submit Form button */}
                        {logsLink ? <Button
                            onClick={() => {
                                window.open(logsLink, '_blank')
                            }}
                            color="inherit"
                            // disabled={!isFormValid()}
                        >
                            Logs
                        </Button> : null}
                    </MenuItem>
                    <MenuItem style={{marginBottom: '20px'}}>
                        {submitting && (
                            <div style={{marginLeft: '40px', width: '50%'}}>
                                {/* LinearProgress component */}
                            </div>
                        )}
                    </MenuItem>
                </Menu>
            </Sidebar>
            <main style={{padding: 10}}>
                <div>
                    <Button className="sb-button" onClick={() => collapseSidebar()}>
                        <MenuIcon/>
                    </Button>
                </div>
            </main>
        </div>
    );
}

export default MainSideBar;
